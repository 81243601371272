import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Img from './Img'
// import Gallery from './blocks/Gallery'
// import List from './blocks/List'
// import Quote from './blocks/Quote'
// import TwoColumn from './blocks/TwoColumn'

// MARKS
const Bold = ({ children }) => <strong>{children}</strong>
const Italic = ({ children }) => <i>{children}</i>

// BLOCKS
const P = ({ children }) => <p>{children}</p>
const H1 = ({ children }) => <h1>{children}</h1>
const H2 = ({ children }) => <h2>{children}</h2>
const H3 = ({ children }) => <h3>{children}</h3>
const H4 = ({ children }) => <h4>{children}</h4>
// const Quote = ({ children }) => <blockquote>{children}</blockquote>

// const EmbeddedEntry = ({ data }) => {
//   const { fields, sys } = data.target;
//   const isTrack = sys.contentType && sys.contentType.sys.id === "track";
//   const isPage = sys.contentType && sys.contentType.sys.id === "page";
//   const isEmbed = sys.contentType && sys.contentType.sys.id === "embed";
//
//   return isTrack ? (
//     <Track {...sys} />
//   ) : isPage ? (
//     <PageLink {...sys} />
//   ) : isEmbed ? (
//     <EmbedCode {...data.target} />
//   ) : (
//     <div className="debug">Missing content!</div>
//   )
// }

const Image = ({ data }) => {
  const { fluid, title } = data.target
  return (
    <div className="rich-text-asset">
      <Img fluid={fluid} alt={title} />
    </div>
  )
}

const Hyperlink = ({ data, children }) => {
	// console.log(data);
	return (
		<a href={data.uri} target="_blank" rel="noopener noreferrer">{children}</a>
	)
}

const DownloadLink = ({ data, children }) => {
	const file = data.target && data.target.file;
	return (
		file ? (
			<a href={file.url} download={file.title} target="_blank">{children}</a>
		) : (
			<span className="c-constraints">[Asset missing]</span>
		)
	)
}

const Highlight = ({ target: { colour }, children }) => (
	<span style={{ color: colour || "#000000" }}>{children}</span>
)

const InlineEntry = ({ data, children }) => {
	const { __typename: type } = data.target

  if (type === 'ContentfulCompanyValue') {
    return <Highlight {...data}>{children}</Highlight>
  }
  
	return false;
}

const Entry = ({ data }) => {
  console.log(data)
  // const { __typename: type } = data.target
  // if (type === 'ContentfulRichTextBlockGallery') {
  //   return <Gallery slides={data.target.slides} />
  // }
  // if (type === 'ContentfulRichTextBlockPageList') {
  //   return <List items={data.target.pages} />
  // }
  // if (type === 'ContentfulRichTextBlockQuote') {
  //   return <Quote quoteText={data.target.quoteText.quoteText} author={data.target.author} />
  // }
  // if (type === 'ContentfulRichTextBlockTwoColumn') {
  //   return <TwoColumn {...data.target} />
  // }

  return false
}

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => <P>{children}</P>,
    [BLOCKS.HEADING_1]: (_node, children) => <H1>{children}</H1>,
    [BLOCKS.HEADING_2]: (_node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (_node, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (_node, children) => <H4>{children}</H4>,
    // [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
    // [BLOCKS.EMBEDDED_ENTRY]: (node, children) => <EmbeddedEntry {...node}>{children}</EmbeddedEntry>,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => <Image {...node}>{children}</Image>,
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => <Entry {...node} />,
		[INLINES.HYPERLINK]: (node, children) => <Hyperlink {...node}>{children}</Hyperlink>,
		[INLINES.ASSET_HYPERLINK]: (node, children) => <DownloadLink {...node}>{children}</DownloadLink>,
		[INLINES.ENTRY_HYPERLINK]: (node, children) => <InlineEntry {...node}>{children}</InlineEntry>
  },
  renderText: text => text.split('\n')
    .reduce((children, textSegment, index) => [...children, index > 0 && <br key={index} />, textSegment], [])
}

const RichText = ({ content }) => (
	content && (
		renderRichText(content, options)
	)
)

RichText.propTypes = {
  content: PropTypes.shape({
		raw: PropTypes.string.isRequired,
		references: PropTypes.array
	})
}

export default RichText
