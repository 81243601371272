// Dependencies
import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"
import { useMouseWheel } from "react-use"
import { useSwipeable } from "react-swipeable"
// Hooks & Helpers
import useLockBodyScroll from "../hooks/useLockBodyScroll"
import useMouse from "../hooks/useMouse"
import useSettings from "../hooks/useSettings"
import useUpdateSizes from "../hooks/useUpdateSizes"
import useSessionStorage from "../hooks/useSessionStorage"
// Components
import { AppActionsContext } from "../components/App"
import Link from "../components/Link"
import LinkType from "../components/LinkType"
import { Heart, Burger, Cross } from "../components/Icons"

const SPLASH_DURATION = 3000

// const Lock = () => {
// 	// useLockBodyScroll();
// 	return null;
// }

// const Cursor = () => {

// 	// UX
// 	const [isLeft, set] = useState(false);

// 	// UI
// 	const { x, y } = useMouse();

// 	useEffect(() => {
// 		if (x > (window.innerWidth / 2)) {
// 			set(false)
// 		} else {
// 			set(true)
// 		}
// 	}, [x]);

// 	return (
// 		<div className={`gallery__cursor down`} style={{ top: y, left: x }} />
// 	)
// }

const MenuLinks = ({ links, pathname, useType }) => {
  return (
    <ul className="has-break-indents">
      {links.map((node, index) => {
        const hasContent = node.content
        const to = hasContent
          ? useType
            ? `${pathname}${node.type.slug}/${node.slug}`
            : `${pathname}${node.slug}`
          : null

        return (
          <li key={`link-${node.id}-${index}`}>
            <LinkType to={to} url={node.externalUrl}>
              {node.title}
            </LinkType>
          </li>
        )
      })}
    </ul>
  )
}

const WorkMenu = ({ data }) => {
  // Content
  const { sectors, countries, cities, boroughs, projects } = data

  // UX
  // useLockBodyScroll();

  return (
    <>
      <div className="p2 nav__column">
        <h5 className="">Sector</h5>
        <MenuLinks pathname="/work/" links={sectors.nodes} />
      </div>
      <div className="p4 nav__column">
        <h5 className="">Location</h5>
        <MenuLinks pathname="/work/" links={countries.nodes} />
        <MenuLinks pathname="/work/" links={cities.nodes} />
        <MenuLinks pathname="/work/" links={boroughs.nodes} />
      </div>
      <div className="p4 nav__column">
        <h5 className="">Work</h5>
        <MenuLinks pathname="/project/" links={projects.nodes} />
      </div>
    </>
  )
}

const ActivityMenu = ({ data }) => {
  // Content
  const { activity, activityTypes, activityYears } = data

  // UX
  // useLockBodyScroll();

  return (
    <>
      <div className="p2 nav__column">
        <h5 className="">Type</h5>
        <MenuLinks pathname="/activity/" links={activityTypes.nodes} />
      </div>
      <div className="p4 nav__column">
        <h5 className="">Year</h5>
        <MenuLinks pathname="/activity/" links={activityYears.nodes} />
      </div>
      <div className="p4 nav__column">
        <h5 className="">Activity</h5>
        <MenuLinks
          useType={true}
          pathname="/activity/"
          links={activity.nodes}
        />
      </div>
    </>
  )
}

const Manifesto = ({
  colour,
  title,
  description,
  showManifesto,
  setManifesto,
}) => {
  // ------------------------------
  // Content
  const formatTitle = title.includes("love") ? (
    <div className="we-love-constraints">
      <span>We</span>
      <Heart />
      <span>constraints</span>
    </div>
  ) : (
    title
  )

  // ------------------------------
  // Constants
  const DIVIDED_HEIGHT = `calc(var(--app-height) / 6)`

  // ------------------------------
  // UX
  const wrapper = useRef()
  const [showCopy, setCopy] = useState(false)
  const [height, setHeight] = useState(
    showManifesto ? DIVIDED_HEIGHT : `var(--manifesto-line-height)`
  )

  useEffect(() => {
    if (!showManifesto) {
      setHeight(`var(--manifesto-line-height)`)
    } else if (showCopy && wrapper.current) {
      setHeight(wrapper.current.offsetHeight)
    } else {
      setHeight(DIVIDED_HEIGHT)
    }
  }, [showCopy, showManifesto])

  // ------------------------------
  // UI
  const [setShowCursor, setCursorToggleState] = useContext(AppActionsContext)

  const toggle = () => {
    if (showManifesto) {
      // If manifesto is fully open, toggle copy...
      setCopy(prev => !prev)
    } else {
      // If the manifesto is fully closed...open it
      setManifesto(true)
    }
  }

  useEffect(() => {
    setCursorToggleState(showCopy)
  }, [showCopy])

  // On "scroll" or "drag" hide manifesto
  // const mouseWheel = useMouseWheel()
  // useEffect((
  // 	useCallback(() => {
  // 		if (mouseWheel && showManifesto) {
  // 			setManifesto(false)
  // 			setShowCursor(false)
  // 		}
  // 	}, [mouseWheel, showManifesto, setManifesto, setShowCursor])
  // ), [mouseWheel])

  // DOM gestures
  const handlers = {
    onMouseEnter: () => !showManifesto || setShowCursor(true),
    onMouseOver: () => !showManifesto || setShowCursor(true),
    onMouseLeave: () => !showManifesto || setShowCursor(false),
    onClick: toggle,
  }

  // ------------------------------
  // CSS
  const css = {
    backgroundColor: colour,
    height: height,
  }

  return (
    <div
      className={`${showManifesto ? "is-showing" : ""} manifesto__value`}
      {...handlers}
      style={css}
    >
      <div className="manifesto__contents guttersx2" ref={wrapper}>
        <h1
          className="header--h1"
          style={{
            // opacity: showManifesto ? 1 : 0,
            height: `calc(var(--app-height) / 6)`,
          }}
        >
          {formatTitle}
        </h1>
        <div
          className="p3 manifesto__text"
          style={{
            opacity: showCopy && showManifesto ? 1 : 0,
          }}
        >
          <p>{description.description}</p>
        </div>
      </div>
    </div>
  )
}

function useScroll() {
  const [isTop, setTop] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY

      if (scrollY > 120) {
        setTop(false)
      } else {
        setTop(true)
      }
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll, false)
  }, [])

  return [isTop]
}

const Title = ({
  showManifesto,
  pageTitle,
  onClick,
  closeManifesto,
  handleCloseClick,
  isNavOpen,
  location,
}) => {
  const name = "Curl la Tourelle Head"

  // UX
  const [showName, toggleName] = useState(false)

  // UI
  const handlers = {
    onMouseEnter: () => toggleName(true),
    onMouseLeave: () => toggleName(false),
    onClick: onClick,
  }

  return (
    <div className="h3 page-title">
      {isNavOpen && !showManifesto ? (
        <>
          <Link to={location.pathname} className="show-desktop">
            {"< Back"}
          </Link>
          <Link to="/" className="show-mobile">
            {name}
          </Link>
        </>
      ) : (
        // <button className="h3 page-title" onClick={handleCloseClick}>{"< Back"}</button>
        <Link to="/" {...handlers}>
          {showManifesto ? name : showName ? name : pageTitle}
        </Link>
      )}
    </div>
  )
}

const MainNavigationLinks = ({
  isWorkOpen,
  isActivityOpen,
  handleOpenWorkMenu,
  handleOpenActivityMenu,
  location,
}) => {
  // Context
  const path = location.pathname

  // UX
  // TODO: Logic state for mobile menu needs reworking because
  // of added complexity for work/activity menus to be URLs
  // as part of the router/browser history
  const isNavOpen = isWorkOpen || isActivityOpen
  const isWork =
    (path.includes("work") && !isActivityOpen) ||
    (path.includes("project") && !isActivityOpen)
  const isStudio = path.includes("studio")
  const isActivity = path.includes("activity") && !isWorkOpen
  const isNews = path.includes("news")
  const isContact = path.includes("contact")

  const activeClassName = bool => (bool ? `active` : ``)

  return (
    <div className="p4">
      <Link to="?menu=work" className={activeClassName(isWork)}>
        Work
      </Link>
      {/* <button className={activeClassName(isWork)} onClick={handleOpenWorkMenu}>Work</button> */}
      <Link className={activeClassName(isStudio)} to="/studio">
        Studio
      </Link>
      <Link to="?menu=activity" className={activeClassName(isActivity)}>
        Activity
      </Link>
      {/* <button className={activeClassName(isActivity)} onClick={handleOpenActivityMenu}>Activity</button> */}
      <Link className={activeClassName(isNews)} to="/news">
        News
      </Link>
      <Link className={activeClassName(isContact)} to="/contact">
        Contact
      </Link>
    </div>
  )
}

function useToggleCursor(condition) {
  // UX
  useEffect(() => {
    if (condition) {
      setShowCursor(false)
    }
  }, [condition])

  // UI
  const [setShowCursor, setCursorToggleState] = useContext(AppActionsContext)

  const handlers = {
    onMouseEnter: () => condition || setShowCursor("down"),
    onMouseOver: () => condition || setShowCursor("down"),
    onMouseLeave: () => condition || setShowCursor(false),
  }

  return handlers
}

function useManifestoTimer(callback, deps = []) {
  const timer = useRef()
  const [hasClicked, setClicked] = useState(false)

  const startTimer = () => {
    if (!hasClicked) {
      clearTimeout(timer.current)
      timer.current = setTimeout(callback, SPLASH_DURATION)
    }
  }

  useEffect(() => {
    startTimer()
    return () => {
      clearTimeout(timer.current)
    }
  }, deps)

  const handleClick = () => {
    setClicked(true)
  }

  const handlers = {
    onMouseOver: startTimer,
    onClick: handleClick,
    onTouchStart: handleClick,
    onMouseLeave: startTimer,
    onMouseEnter: startTimer,
  }

  return [handlers]
}

const NavContents = ({ showManifesto, children }) => {
  const ref = useRef()
  // Lock body scroll when nav is open in any way...
  useLockBodyScroll(showManifesto, ref)

  return (
    <div className="nav__contents" ref={ref}>
      {children}
    </div>
  )
}

const Navigation = ({
  title = "Curl la Tourelle Head",
  hasManifesto = false,
}) => {
  // Refs
  const headerRef = useRef()
  const navRef = useRef()

  // Content
  const location = useLocation()
  const data = useContent()
  const { manifesto } = useSettings()
  const [pageTitle, setPageTitle] = useState(title)

  // UX
  const [showManifesto, setManifesto] = useSessionStorage(
    "clth-show-manifesto",
    hasManifesto
  )
  const [isWorkOpen, setWorkOpen] = useState(false)
  const [isActivityOpen, setActivityOpen] = useState(false)
  const isNavOpen = isWorkOpen || isActivityOpen
  const [isTop] = useScroll()

  // Close manifesto after set time
  const [idleHandlers] = useManifestoTimer(() => {
    setManifesto(false)
  }, [hasManifesto, showManifesto, isWorkOpen, isActivityOpen])

  // Open/Close menu based on routes...
  useEffect(() => {
    const menuOption = location.search.split("?menu=")[1]
    if (menuOption) {
      if (menuOption === "work") {
        handleOpenWorkMenu()
      } else if (menuOption === "activity") {
        handleOpenActivityMenu()
      }
    } else {
      handleCloseClick()
    }
  }, [location.search])

  const handleMenuTitles = useCallback(() => {
    if (isWorkOpen) {
      setPageTitle("Work")
    } else if (isActivityOpen) {
      setPageTitle("Activity")
    } else {
      setPageTitle(title)
    }
  }, [isWorkOpen, isActivityOpen, title, setPageTitle])

  useEffect(handleMenuTitles, [isWorkOpen, isActivityOpen])

  // Lock body scroll when nav is open in any way...
  useLockBodyScroll(isWorkOpen || isActivityOpen, navRef)

  // UI
  const cursorHandlers = useToggleCursor(!showManifesto)

  const handleCloseClick = () => {
    setWorkOpen(false)
    setActivityOpen(false)
  }

  const closeManifesto = () => {
    setManifesto(false)
  }

  const handleOpenMobileMenu = () => {
    const isActivity = location.pathname.includes("activity")
    setWorkOpen(!isActivity)
    setActivityOpen(isActivity)
  }

  const handleOpenWorkMenu = () => {
    setWorkOpen(true)
    setActivityOpen(false)
    navRef.current.scrollTo(0, 0)
  }

  const handleOpenActivityMenu = () => {
    setActivityOpen(true)
    setWorkOpen(false)
    navRef.current.scrollTo(0, 0)
  }

  // CSS
  const updateSizes = () => {
    if (headerRef.current) {
      document.documentElement.style.setProperty(
        "--header-height",
        `${headerRef.current.offsetHeight}px`
      )
    }
    if (navRef.current) {
      document.documentElement.style.setProperty(
        "--nav-height",
        `${navRef.current.offsetHeight}px`
      )
    }
  }

  useUpdateSizes(updateSizes, [isNavOpen || showManifesto, location.path])

  return (
    <nav
      className={`${isTop || isNavOpen ? "" : "tucked-in"} ${
        isNavOpen ? "open" : "closed"
      } ${showManifesto ? "show-manifesto" : "close-manifesto"}`}
      {...idleHandlers}
      ref={navRef}
    >
      <NavContents showManifesto={showManifesto}>
        {/* Manifesto, and it's stripes */}
        {manifesto.map(entry => (
          <Manifesto
            {...entry}
            showManifesto={showManifesto}
            setManifesto={setManifesto}
            key={entry.title}
          />
        ))}

        {/* {showManifesto && (
					<Lock />
				)} */}

        {/* Header with nav links and page title/site title */}
        <div
          className="nav__links"
          ref={headerRef}
          onClick={closeManifesto}
          {...cursorHandlers}
        >
          <Title
            showManifesto={showManifesto}
            closeManifesto={closeManifesto}
            handleCloseClick={handleCloseClick}
            isNavOpen={isNavOpen}
            onClick={closeManifesto}
            pageTitle={pageTitle}
            location={location}
          />

          {!showManifesto && (
            <div className="nav__header__links" onClick={closeManifesto}>
              {!isNavOpen && (
                <MainNavigationLinks
                  isWorkOpen={isWorkOpen}
                  isActivityOpen={isActivityOpen}
                  handleOpenWorkMenu={handleOpenWorkMenu}
                  handleOpenActivityMenu={handleOpenActivityMenu}
                  location={location}
                />
              )}
            </div>
          )}

          <div
            className="nav__menu-icon"
            onClick={!isNavOpen ? handleOpenMobileMenu : handleCloseClick}
          >
            {!isNavOpen ? <Burger /> : <Cross />}
          </div>
        </div>
      </NavContents>

      {isNavOpen && (
        <Link
          to={location.pathname}
          className="nav__menu-close"
          onClick={handleCloseClick}
        >
          <Cross />
        </Link>
      )}

      {isNavOpen && !showManifesto && (
        <div className="nav__menu__links">
          <MainNavigationLinks
            isWorkOpen={isWorkOpen}
            isActivityOpen={isActivityOpen}
            handleOpenWorkMenu={handleOpenWorkMenu}
            handleOpenActivityMenu={handleOpenActivityMenu}
            location={location}
          />
        </div>
      )}

      {isNavOpen && !showManifesto && (
        <div className="nav__menu has-links">
          <div className="nav__menu__columns guttersx2">
            {isWorkOpen && <WorkMenu data={data} />}
            {isActivityOpen && <ActivityMenu data={data} />}
          </div>
        </div>
      )}
    </nav>
  )
}

function useContent() {
  const data = useStaticQuery(graphql`
    {
      sectors: allContentfulProjectSector(sort: { fields: title }) {
        nodes {
          id
          title
          slug
          content: slug
        }
      }
      countries: allContentfulProjectLocation(
        sort: { fields: title }
        filter: { type: { eq: "Country" } }
      ) {
        nodes {
          id
          title
          slug
          content: slug
        }
      }
      cities: allContentfulProjectLocation(
        sort: { fields: title }
        filter: { type: { eq: "City" } }
      ) {
        nodes {
          id
          title
          slug
          content: slug
        }
      }
      boroughs: allContentfulProjectLocation(
        sort: { fields: title }
        filter: { type: { eq: "Borough" } }
      ) {
        nodes {
          id
          title
          slug
          content: slug
        }
      }
      projects: allContentfulProject(
        sort: { fields: title }
        filter: { hideFromNavigation: { ne: true } }
      ) {
        nodes {
          id
          title
          slug
          content: slug
        }
      }
      activityTypes: allContentfulActivityType(
        sort: { fields: title, order: DESC }
      ) {
        nodes {
          id
          title
          slug
          content: slug
        }
      }
      activityYears: allContentfulActivityYear(
        sort: { fields: year, order: DESC }
      ) {
        nodes {
          id
          title: year
          slug: year
          content: year
        }
      }
      activity: allContentfulActivity(sort: { fields: date, order: DESC }) {
        nodes {
          id
          title: dateline
          slug
          year: date(formatString: "YYYY")
          type {
            slug
          }
          externalUrl
          content: information {
            raw
          }
        }
      }
    }
  `)
  return data
}

export default Navigation
