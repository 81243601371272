// Dependencies
import React, { useState } from "react";

export const AppContext = React.createContext();
export const AppActionsContext = React.createContext();

const App = ({ children }) => {

	const [showCursor, setShowCursor] = useState(false);
	const [cursorToggleState, setCursorToggleState] = useState(false);

	return (
		<AppContext.Provider value={[showCursor, cursorToggleState]}>
			<AppActionsContext.Provider value={[setShowCursor, setCursorToggleState]}>
				{children}
			</AppActionsContext.Provider>
		</AppContext.Provider>
	)
}

export default App