// Dependencies
import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
// Hooks & Helpers
import useSettings from "../hooks/useSettings";
// Components
// ...

function Meta({ title, description, shareImage, lang, meta, invertPage }) {

  const { defaultMetaTitle, defaultMetaDescription, defaultMetaImage } = useSettings();

  const metaTitle = title ? `${title} | CLTH` : defaultMetaTitle;
  const metaDescription = description || defaultMetaDescription;
	const metaImage = `https:${shareImage}` || defaultMetaImage ? defaultMetaImage.fluid.src : '';
	
	const css = invertPage ? `
		:root {
			--background: var(--black);
  		--color: var(--white);
		}
	` : `
		:root {
			--background: var(--white);
			--color: var(--black);
		}
	`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `@cltharchitects`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          property: 'twitter:image',
          content: metaImage,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      style={[{
        "cssText": css
      }]}
    />
  );
};

Meta.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Meta.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  shareImage: PropTypes.string
};

export default Meta;
