// Dependencies
import React from "react";
// Components
import RichText from "../components/RichText"
// Hooks
import useSettings from "../hooks/useSettings";
// Constants
const YEAR = new Date().getFullYear();

const Legal = () => (
	<div className="footer-credits h5">
		<span>{`Curl la Tourelle Head © ${YEAR} `}</span>
		<span>Design & Art direction: <a href="http://bobdesign.co.uk/" target="_blank" rel="noopener">BOB Design</a></span>
		<span>Web development: <a href="https://official.business" target="_blank" rel="noopener">Official Business</a></span>
	</div>
)

const Footer = ({ hideFooter, invertPage }) => {

	const { title, telephone, email, notes } = useSettings();

	return (
		<footer className={`guttersx2`}>
			{!hideFooter ? (
				<div className={`footer-contents ${invertPage ? 'white-underlines' : ''}`}>
					<div className=" h1-nudge">
						<h1><span>{title}</span></h1>
						<h1><span>{telephone}</span></h1>
						<h1><a href={`mailto:${email}`} className="link" target="_blank">{email}</a></h1>
					</div>
					<div className="outerx4 has-links">
						<RichText content={notes} />
					</div>
					<div>
						<Legal />
					</div>
				</div>
			) : (
				<Legal />
			)}
		</footer>
	)
}

export default Footer