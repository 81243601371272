/* eslint-disable jsx-a11y/alt-text */
// Dependencies
import React, {
  useState, useEffect, useCallback, useRef
} from 'react'
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
// import Image from "../components/Image";

// const isBrowser = typeof window !== `undefined`

function useImageLoad(callback) {
  const imageRef = useRef()
  const [loaded, set] = useState(false)
  const handleLoad = () => {
    set(true)
		!callback || callback()
  }
  useEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      set(true)
			!callback || callback()
    }
  }, [])

  return [loaded, handleLoad, imageRef]
}



const Img = (props) => {
	const { className, fluid, file, style, setRef, onLoad } = props
  const [loaded, handleLoad, imageRef] = useImageLoad(onLoad)
  const assignRefs = useCallback(node => {
    if (node) {
      if (setRef) {
        setRef.current = node
      }

      if (imageRef) {
        imageRef.current = node
      }
    }
  }, [])

  return fluid ? (
		<img
			className={className}
			src={fluid.src}
			srcSet={fluid.srcSet}
			sizes={fluid.sizes}
			onLoad={handleLoad}
			ref={assignRefs}
			loading="lazy"
			style={{
				...style,
				cursor: loaded ? 'inherit' : 'progress',
				opacity: loaded ? 1 : 0,
				transition: 'opacity 0.5s'
			}}
		/>
  ) : (
    <img
      className={className}
      src={(file && file.url) || ''}
      onLoad={handleLoad}
      ref={assignRefs}
      loading="lazy"
      style={{
        ...style,
				cursor: loaded ? 'inherit' : 'progress',
        opacity: loaded ? 1 : 0,
        transition: 'opacity 0.5s'
      }}
    />
  )
}

export const ImageLoader = ({ asset }) => {

	const [loaded, set] = useState(false)

	const handleLoad = () => {
		set(true)
	}

	return (
		<div className={`image-loader ${!loaded ? 'loading' : ''}`}>
			<Img {...asset} onLoad={handleLoad} />
		</div>
	)
}

export default Img
