import { graphql, useStaticQuery } from "gatsby";

export default function () {
  const data = useStaticQuery(graphql`
    {
      allContentfulSettings {
        nodes {
          defaultMetaTitle
          defaultMetaDescription
          defaultMetaImage {
            fluid(maxHeight: 500) {
              ...GatsbyContentfulFluid_noBase64
            }
					}
					manifesto {
						title
						description {
							description
						}
						colour
					}
        }
      }
			allContentfulContact {
				nodes {
					title
					telephone
					email
					notes {
						raw
						references {
							... on ContentfulAsset {
								contentful_id
								__typename
								id
								title
								file {
									url
								}
							}
						}
					}
				}
			}
    }
  `)
  return {
		...data.allContentfulSettings.nodes[0],
		...data.allContentfulContact.nodes[0]
	}
}
