// Dependencies
import React from "react";
import { Link } from "gatsby";

const LinkType = ({ className, to, url, children }) => {

	return (
		url ? (
			<a href={url} target="_blank" rel="noreferrer noopener" className={className}>{children}</a>
		) : to ? (
			<Link to={to} className={className}>
				{children}
			</Link>
		) : (
			<div className={className}>{children}</div>
		)
	)
}

export default React.memo(LinkType)