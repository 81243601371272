// Dependencies
import React from "react";
// Components
import App from "../components/App";
import Navigation from "../components/Navigation";
import Meta from "../components/Meta";
import Footer from "../components/Footer"
import ToggleCursor from "../components/ToggleCursor"
// Hooks
import useSettings from "../hooks/useSettings";
// CSS
import "../css/styles.scss";

const Layout = ({ title, description, shareImage, headerTitle, invertPage, hasManifesto, hideFooter, children }) => {

	return (
		<App>
			<main>
				<Meta title={title} description={description} shareImage={shareImage} invertPage={invertPage} />	
				<Navigation title={headerTitle} hasManifesto={hasManifesto} />	
				{children}
				<Footer hideFooter={hideFooter} invertPage={invertPage} />
			</main>

			<ToggleCursor />
		</App>
	)
}

export default Layout