// Dependencies
import React, { useContext, useEffect } from "react"
// Hooks & Helpers
import useMouse from "../hooks/useMouse";
// Components
import { AppContext, AppActionsContext } from "../components/App";

export function useToggleHover() {

	const [setShowCursor] = useContext(AppActionsContext)

	const handlers = {
		onMouseEnter: () => setShowCursor(false),
		onMouseOver: () => setShowCursor(false),
		onMouseLeave: () => setShowCursor(true)
	}

	return [handlers]
}

const ToggleCursor = () => {

	// UX
	const [showCursor, cursorToggleState] = useContext(AppContext);

	useEffect(() => {
		if (showCursor) {
			document.documentElement.style.setProperty('--cursor', 'none');
		} else {
			document.documentElement.style.setProperty('--cursor', 'auto');
		}
	}, [showCursor])

	// UI
	const { x, y } = useMouse();

	const transform = showCursor === 'down' ? (
		`translate(-50%, -50%)`
	) : (
		`translate(-50%, -50%) rotate(${!cursorToggleState ? '45deg' : '0deg'})`
	)

	const css = {
		top: y,
		left: x,
		opacity: showCursor ? 1 : 0,
		transform: transform
	}

	return (
		<div className={`toggle__cursor ${showCursor}`} style={css} />
	)
}

export default ToggleCursor